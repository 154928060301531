import { graphql, PageProps } from "gatsby"
import React from "react"
import PageComponent from "../components/page/page"
import { TemplatePageQueryQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplatePageQueryQuery>

const Page: React.FC<IProps> = ({ data: { wpPage } }) => {
  const key = String(wpPage?.uri)

  return <PageComponent data={wpPage} sessionKey={key} />
}

export default Page

export const query = graphql`
  fragment SEO on WpPostTypeSEO {
    metaDesc
    title
    opengraphUrl
    opengraphSiteName
    opengraphTitle
    opengraphDescription
    opengraphImage {
      sourceUrl
      altText
      mediaDetails {
        height
        width
      }
    }
  }
  fragment HeroCarrousel on WpPage_HeroCarrousel {
    toonCarrousel
    achtergrondKleur
    carrousel {
      ... on WpCarrousel {
        ...Carrousel
      }
    }
  }

  fragment CarrouselFields on WpCarrousel_CarrouselFields {
    items {
      item {
        tekst
        locatieTekst
        afbeelding {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  }

  fragment Carrousel on WpCarrousel {
    id
    carrousel_fields {
      ...CarrouselFields
    }
  }

  fragment FormFieldNode on WpFormField {
    __typename
    type
    id
    fieldId
    fieldLabel
    required
    ... on WpTextboxField {
      __typename
      placeholder
      default
    }
    ... on WpTextareaField {
      __typename
      placeholder
      default
    }
    ... on WpSubmitField {
      __typename
      processingLabel
    }
    ... on WpPhoneField {
      __typename
      placeholder
    }
    ... on WpEmailField {
      __typename
      placeholder
    }
    ... on WpDateField {
      __typename
      dateFormat
    }
    ... on WpNumberField {
      __typename
      placeholder
      default
    }
    ... on WpListselectField {
      __typename
      options {
        label
        value
        selected
      }
    }
    ... on WpHtmlField {
      __typename
      default
    }
    ... on WpCheckboxField {
      __typename
      defaultValue
      description {
        descText
      }
    }
  }
  fragment Form on WpForm {
    formId
    title
    showTitle
    hideComplete
    clearComplete
    addSubmit
    wpFields {
      nodes {
        ...FormFieldNode
      }
    }
  }

  fragment PageBuilderImage on WpPage_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    afbeelding {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  fragment PageBuilderCTA on WpPage_PageBuilder_PageBuilder_Cta {
    __typename
    titel
    subtitel
    uitlijningTitel
    linkKnop {
      title
      url
      target
    }
    uitlijningKnop
  }
  fragment PageBuilderText on WpPage_PageBuilder_PageBuilder_TekstEditor {
    __typename
    instellingen {
      achtergrond
      breedte
    }
    tekst
  }
  fragment PageBuilderSpacer on WpPage_PageBuilder_PageBuilder_Spacer {
    __typename
    hoogte
  }
  fragment PageBuilderFaq on WpPage_PageBuilder_PageBuilder_Faq {
    __typename
    titel
    tekst
    kleur
    standaardGesloten
    metaData
  }
  fragment UitgelichteActiviteit on WpActiviteit {
    __typename
    id
    uri
    title
    excerpt
    activiteit_fields {
      dates {
        text
      }
    }
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
  fragment UitgelichteErvaring on WpErvaring {
    __typename
    id
    uri
    title
    excerpt
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
  fragment PageBuilderUitgelichteActiviteit on WpPage_PageBuilder_PageBuilder_UitgelichteActiviteit {
    __typename
    flip
    onlyImg
    activiteit {
      ...UitgelichteActiviteit
    }
  }
  fragment PageBuilderUitgelichteErvaring on WpPage_PageBuilder_PageBuilder_UitgelichteErvaring {
    __typename
    flip
    onlyImg
    ervaring {
      ...UitgelichteErvaring
    }
  }
  fragment LaatsteBerichtenData on WpContentNode {
    id
    uri
    ... on WpNodeWithTitle {
      title
    }
    ... on WpNodeWithExcerpt {
      excerpt
    }
  }
  fragment PageBuilderLaatsteBerichten on WpPage_PageBuilder_PageBuilder_LaatsteBerichten {
    __typename
    type {
      nodesOfPostTypes {
        nodes(limit: 5) {
          ...LaatsteBerichtenData
        }
      }
    }
    aantal
  }
  fragment PageBuilderAgenda on WpPage_PageBuilder_PageBuilder_Agenda {
    __typename
    weergave
    tags {
      id
    }
  }
  fragment PageBuilderForm on WpPage_PageBuilder_PageBuilder_Formulier {
    __typename
    nieuwsbriefFormulier
    addtext
    text
    redirect {
      url
    }
    form {
      ...Form
    }
  }
  fragment PageBuilderOverzicht on WpPage_PageBuilder_PageBuilder_OverzichtPaginas {
    __typename
    format
    sortering
    tags {
      id
    }
  }
  fragment PageBuilderYoutube on WpPage_PageBuilder_PageBuilder_Youtube {
    __typename
    titel
    toonTitel
    youtubeId
    aspectRatio
    tekst
    metTekst {
      plaatsTekst
      achtergrond
      breedteContainer
    }
  }
  fragment PageBuilderFacebook on WpPage_PageBuilder_PageBuilder_Facebook {
    __typename
    tabs
  }
  fragment PageBuilderButtonsGroup on WpPage_PageBuilder_PageBuilder_ButtonsGroup {
    __typename
    buttons {
      button {
        url
        title
        target
      }
    }
  }
  fragment PageBuilderBlocks on WpPage_PageBuilder_PageBuilder {
    ... on WpPage_PageBuilder_PageBuilder_TekstEditor {
      ...PageBuilderText
    }
    ... on WpPage_PageBuilder_PageBuilder_Spacer {
      ...PageBuilderSpacer
    }
    ... on WpPage_PageBuilder_PageBuilder_Afbeelding {
      ...PageBuilderImage
    }
    ... on WpPage_PageBuilder_PageBuilder_Faq {
      ...PageBuilderFaq
    }
    ... on WpPage_PageBuilder_PageBuilder_Cta {
      ...PageBuilderCTA
    }
    ... on WpPage_PageBuilder_PageBuilder_UitgelichteActiviteit {
      ...PageBuilderUitgelichteActiviteit
    }
    ... on WpPage_PageBuilder_PageBuilder_UitgelichteErvaring {
      ...PageBuilderUitgelichteErvaring
    }
    ... on WpPage_PageBuilder_PageBuilder_LaatsteBerichten {
      ...PageBuilderLaatsteBerichten
    }
    ... on WpPage_PageBuilder_PageBuilder_OverzichtPaginas {
      ...PageBuilderOverzicht
    }
    ... on WpPage_PageBuilder_PageBuilder_Agenda {
      ...PageBuilderAgenda
    }
    ... on WpPage_PageBuilder_PageBuilder_Formulier {
      ...PageBuilderForm
    }
    ... on WpPage_PageBuilder_PageBuilder_Youtube {
      ...PageBuilderYoutube
    }
    ... on WpPage_PageBuilder_PageBuilder_Facebook {
      ...PageBuilderFacebook
    }
    ... on WpPage_PageBuilder_PageBuilder_ButtonsGroup {
      ...PageBuilderButtonsGroup
    }
  }
  fragment PageBuilder on WpPage_PageBuilder {
    pageBuilder {
      ...PageBuilderBlocks
    }
  }
  fragment wpPage on WpPage {
    id
    uri
    seo {
      ...SEO
    }
    hero_carrousel {
      ...HeroCarrousel
    }
    page_builder {
      ...PageBuilder
    }
  }
  query TemplatePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wpPage
    }
  }
`
